/**
 * 分页功能混入
 * 使用需要规定获取表格数据的方法命名为  getList
 */

export default {
  computed: {
    // 分页选项
    pageSizes() {
      let { allPage = 1, pageSize = 10, allNum = 1 } = this.page;
      let sizeList = [];
      let num = Math.ceil(allNum / 10);
      for (let i = 1; i <= num && i <= 20; i++) {
        sizeList.push(i * 10);
      }
      return sizeList;
    }
  },
  data(){
    return{
      currentPage: 1,
      //排序
      orderData:{}
    }
  },
  methods: {
    // 更改分页
    changeSize(size) {
      let {
        searchData = {}, orderData = {}
      } = this;
      let param = {
        pageNum: 1,
        pageSize: parseInt(size)
      };
      return this.getList(Object.assign(searchData, param, orderData));
    },
    // 上一页
    changePage(page) {
      let {
        searchData = {}, orderData = {}
      } = this;
      let {
        pageSize = 10
      } = this.page;
      this.currentPage = page;
      let param = {
        pageNum: page,
        pageSize
      };
      return this.getList(Object.assign(searchData, param, orderData));
    },
    //排序
    handleSort(data){
      let { searchData = {} } = this;
      this.orderData = {
        orderKey:data.prop,
        isAsc:data.order == 'ascending'? true : false
      }
      this.getList({pageNum: this.currentPage, ...this.orderData, ...searchData} );
    }
  }
};
