
<template>
  <div class="update c-bg-white">
    <h1 class="title ft-bold">更新日志</h1>
    <record v-for="(item) in list" :key="item.id" :record="item"></record>
    <!-- 分页管理 -->
    <div style="textAlign:center">
      <el-pagination
        background
        :page-sizes="pageSizes"
        :page-size="10"
        :current-page="currentPage"
        layout="total, sizes, prev, pager, next"
        :total="page.allNum"
        @size-change="changeSize"
        @current-change="changePage"
      ></el-pagination>
    </div>

  </div>
</template>

<script>
import record from "./components/record"
import service from'./api'

import pagination from '@/mixins/pagination';
export default{
  mixins: [pagination],
  components:{ record },
  data(){
    return{
      list:[],
      page:{}
    }
  },
  created(){
    this.getList({pageNum:1})
  },
  methods:{
    //页面初始化
    getList(params){
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;
      service.getRecord(params).then(res => {
        this.list = res.list;
        this.page = res.page;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.update{
  border-radius: 3px;
  text-align: left;
  padding:40px;
  .title{
    font-size: 20px;
    margin-bottom:20px;
    color:#222222;
  }
}
</style>
