<template>
  <div class="record">
    <div class="date">
      <span class="version">版本号：{{record.version}}</span>
      {{record.created_at | toDate}}
    </div>
    <div class="content">
      <div v-for="(item, index) in list" :key="index" class="desc">
        <span>{{index + 1}}</span>.
        <span class="type" v-if="item.type">{{item.type}}</span>
        <span>{{item.desc}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "record",
  props:{
    record:{
      type: Object,
      default: () => {}
    }
  },
  data(){
    return {
      list:[]
    }
  },
  created() {
    this.list = [];
    let arr = this.record.content.split("\n");
    arr.map(item => {
      if(item.indexOf('`') > -1){
        let obj ={
          type: '',
          desc: ''
        }
        obj.type = item.split('`')[1];
        obj.desc = item.split('`')[2];
        this.list.push(obj)
      }else{
        let obj ={
          desc: ''
        }
        obj.desc = item;
        this.list.push(obj)
      }
    })
  },
  filters:{
    toDate(d){
      return dayjs(d).format('YYYY-MM-DD');
    }
  }
}
</script>

<style lang="scss" scoped>
.record{
  margin:40px 0;
  .date{
    font-size: 16px;
    font-weight: 600;
    .version{
      margin-right:20px;
    }
  }
  .content{
    font-size:14px;
    margin: 10px 0;
    padding-left: 10px;
    .desc{
      line-height:1.6;
      color:#222;
      .type{
        background-color: #f9f9fa;
        font-size: .85em;
        padding: 2px 4px;
        border-radius: 2px;
        display: inline-block;
        margin-right:5px;
      }
    }
  }
}
</style>
