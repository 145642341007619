import API from "../../api/index";

export default{
  // 获取版本记录
  getRecord: (params) => {
    return API.requestGet({
      url: "api/common/version/selectAll",
      auth: false,
      params:{
        type:0,
        ...params
      }
    }, false);
  },
}
